
.careers-hero-section {
    background-color: #9CD58F;
    background: url("/assets/img/careers/RecuitmentPage-Banner-1-01.png") no-repeat center top;
    background-size: cover;
    margin-bottom: 40px; 
}

.careers-hero-section-row {
    height: 300px;
}


.careers-hero-heading span {
    background-color: #fff;
    
}

.careers-aboutUs-section {
    background-color: #9CD5BF;
    margin-bottom: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
}

.careers-aboutUs-heading {
    font-size: 3.5rem;
}

.careers-receptionists-p-m-b, .careers-provider-p-m-b {
    margin-bottom: 80px;
}

.careers-moreInfo-btn2 {
    display: block;
    width: 100%;
    font-size: 24px;
    background-color: #464847;
    border-radius: 0px;
}

.careers-moreInfo-btn {
    display: block;
    width: 70%;
    font-size: 24px;
    background-color: #464847;
    border-radius: 0px;
}

.careers-listings-col-background {
    height:100%; 
    background-color: white; 
    padding: 15px;
}

.careers-listings-col-border {
    height:100%; 
    border: solid 3px #464847; 
    border-radius: 3px; 
    padding: 30px;
}

.careers-listings-col-border2 {
    height:100%; 
    border: solid 3px #464847; 
    border-radius: 3px; 
}

.careers-listings-col-btn-pos {
    position: absolute;
    bottom: 40px;
    width: 60%;
    left: 20%;
    right: 20%;
}

.careers-listings-click-apply-btn {
    display: block; 
    width: 100%; 
    margin: 5px 0; 
    background-color: white; 
    font-size: 28px; 
    border: none; 
    letter-spacing: 2px;
}

.careers-links-section {
    margin-bottom:80px; 
    padding-top: 80px; 
    padding-bottom: 80px; 
    color: #515151; 
    background-color: #E5D188
}

.careers-links-heading-background {
    background-color: white; 
    padding: 10px 30px;
}

.careers-links-heading-text {
    margin: 15px 0; 
    letter-spacing: 2px;
}

.careers-links-col-background {
    background-color: white; 
    padding: 20px 15px;
}

.careers-links-col-heading {
    color: #515151; 
    letter-spacing: 1px;
}



/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .careers-hero-section-row {
        height: 700px;
    }

    .careers-hero-heading {
        font-size: 6rem;
    }
    .careers-receptionists-p-m-b {
        margin-bottom: 106px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}


@media (min-width: 1170px) {
    .careers-provider-p-m-b {
    margin-bottom: 150px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}