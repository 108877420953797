.editor-content {
    display: none;
}

.cms-editor-active .editor-content {
    display: block;
}


body, h1, h2, h3, h4, h5, h6 {
    font-family: 'Jost', sans-serif;
	color:#515151;
}

.bakersfield-pediatics-green {
    color:rgb(4,149,72);
}

.m-b-25 {
    margin-bottom: 25px;
}

.p-0-25pct {
    padding: 0 25%;
}

.navbar-brand img {
    width: 280px;
    height: auto;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .navbar-brand img {
        width: auto;
        height: 30px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}


@media (min-width: 1170px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
