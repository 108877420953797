
.covid-procedures-hero-section {
    background-color: #9CD58F;
    background: url("/assets/img/covid-procedures/BP-Careers-Image1-01.png") no-repeat center top;
    background-size: cover;
    margin-bottom: 40px; 
}

.covid-procedures-hero-section-row {
    height: 300px;
}

.covid-procedures-hero-heading {
    font-size: 1.2rem;
}

.covid-procedures-hero-heading p {
    background-color: #fff;   
}

.covid-procedures-hero-heading span {
    background-color: #fff;   
}

.covid-procedures-info-section {
    background-color: #97B296;
    margin-bottom: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
}

.covid-procedures-heading {
    font-size: 3.5rem;
}

.covid-procedures-heading2 {
    font-size: 1.5rem;
}


.covid-procedures-info-col-background {
    height:100%; 
    background-color: white; 
    padding: 15px;
}

.covid-procedures-info-col-border {
    height:100%; 
    border: solid 3px #464847; 
    border-radius: 3px; 
    padding: 30px;
}

.covid-procedures-col-border2 {
    height:100%; 
    border: solid 3px #464847; 
    border-radius: 3px; 
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .covid-procedures-hero-section-row {
        height: 700px;
    }

    .covid-procedures-hero-heading {
        font-size: 2rem;
    }
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 992px) {
    .covid-procedures-hero-heading {
        font-size: 3rem;
    }
}