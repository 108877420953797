
.home-hero {
    height: 652px;
    background-position: center top;
    background-attachment: fixed;
    background-repeat: no-repeat;
}
 
/*
.hero-text-container h1 {
    color: rgb(255, 255, 255);
    font-size: 4.5rem;
    margin-top: 7rem;
    margin-bottom: 1.5rem;
    font-family: 'Futura';
}

.hero-text-container p {
    color: rgb(7, 87, 80);
    font-size: 1.7rem;
}

*/

// Temporary for Careers Hiring Hero
// If paragraph text is a link
.hero-text-container a:hover {
    text-decoration-color: rgb(76, 114, 95);
}



/* Small devices (tablets, 768px and up) */
@media (min-width: 576px) {
    .hero-text-container {
        margin-top: 4rem;
    }
    .hero-text-container h1 {
        margin-top: 2rem;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 768px) {
    .hero-text-container {
        margin-top: 7rem;
    }
}


@media (min-width: 992px) {
    .hero-text-container h1 {
        margin-top: 8rem;
        font-size: 4rem;
        margin-bottom: .5rem;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}