
.new-patients-ul {
    color: #106cce;
    margin-bottom: 6rem;
    padding: 0;
    list-style: none;
    font-size: 1.5rem;
}

.miscellaneous-ul {
    color: #106cce;
    margin-bottom: 6rem;
    padding: 0;
    list-style: none;
    font-size: 1.5rem;
}

.faq-text {
    color: #1bd0ca;
}

.faq-contact-text {
    color: #4ab7d3;
}
