

.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.bg-w {
    background-color: white;
}

.bg-whitesmoke {
    background-color: WhiteSmoke;
}

.text-greenish {
	color: #5DA47D;
}

.text-orangish {
	color: #DDA952;
}

.triangle_l-w{
	float: right;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 25px 25px 25px 0;
    border-color: transparent #ffffff transparent transparent;
}

.swallowtail {
    /* width: 25%; */
    width: 75%;
	height: 50px;
	display: block;
	float: left;
	-webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
    transition: width 1s;
}
.swallowtail-green {
	background-color: #5DA47D;
	background:  -moz-linear-gradient(to bottom, #5DA47D 0%,#5DA47D 50%,#8CDCB0 51%,#8CDCB0 100%);
	background: -webkit-linear-gradient(to bottom, #5DA47D 0%,#5DA47D 50%,#8CDCB0 51%,#8CDCB0 100%);
	background:  -o-linear-gradient(to bottom, #5DA47D 0%,#5DA47D 50%,#8CDCB0 51%,#8CDCB0 100%);
	background: -ms-linear-gradient(to bottom, #5DA47D 0%,#5DA47D 50%,#8CDCB0 51%,#8CDCB0 100%);
	background: linear-gradient(to bottom, #5DA47D 0%,#5DA47D 50%,#8CDCB0 51%,#8CDCB0 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' #5DA47D', endColorstr='#8CDCB0', GradientType=1 );
}
.swallowtail-teal {
	background-color: #82D3CF;
	background:  -moz-linear-gradient(to bottom, #82D3CF 0%,#82D3CF 50%,#BBE7E5 51%,#BBE7E5 100%);
	background:  -webkit-linear-gradient(to bottom, #82D3CF 0%,#82D3CF 50%,#BBE7E5 51%,#BBE7E5 100%);
	background:  -o-linear-gradient(to bottom, #82D3CF 0%,#82D3CF 50%,#BBE7E5 51%,#BBE7E5 100%);
	background:  -ms-linear-gradient(to bottom, #82D3CF 0%,#82D3CF 50%,#BBE7E5 51%,#BBE7E5 100%);
	background:  linear-gradient(to bottom, #82D3CF 0%,#82D3CF 50%,#BBE7E5 51%,#BBE7E5 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' #82D3CF', endColorstr='#BBE7E5', GradientType=1 );
}
.swallowtail-pink {
	background-color: #E7A4D7;
	background:  -moz-linear-gradient(to bottom, #E7A4D7 0%,#E7A4D7 50%,#F4CDE5 51%,#F4CDE5 100%);
	background:  -webkit-linear-gradient(to bottom, #E7A4D7 0%,#E7A4D7 50%,#F4CDE5 51%,#F4CDE5 100%);
	background:  -o-linear-gradient(to bottom, #E7A4D7 0%,#E7A4D7 50%,#F4CDE5 51%,#F4CDE5 100%);
	background:  -ms-linear-gradient(to bottom, #E7A4D7 0%,#E7A4D7 50%,#F4CDE5 51%,#F4CDE5 100%);
	background: linear-gradient(to bottom, #E7A4D7 0%,#E7A4D7 50%,#F4CDE5 51%,#F4CDE5 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' #E7A4D7', endColorstr='#F4CDE5', GradientType=1 );
}
.swallowtail-orange {
	background-color: #DDA952;
	background:  -moz-linear-gradient(to bottom, #DDA952 0%,#DDA952 50%,#EDCF92 51%,#EDCF92 100%);
	background:  -webkit-linear-gradient(to bottom, #DDA952 0%,#DDA952 50%,#EDCF92 51%,#EDCF92 100%);
	background:  -o-linear-gradient(to bottom, #DDA952 0%,#DDA952 50%,#EDCF92 51%,#EDCF92 100%);
	background:  -ms-linear-gradient(to bottom, #DDA952 0%,#DDA952 50%,#EDCF92 51%,#EDCF92 100%);
	background:  linear-gradient(to bottom, #DDA952 0%,#DDA952 50%,#EDCF92 51%,#EDCF92 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' #DDA952', endColorstr='#EDCF92', GradientType=1 );
}
.swallowtail-lime {
	background-color: #ABCB7F;
	background:  -moz-linear-gradient(to bottom, #ABCB7F 0%,#ABCB7F 50%,#CCE7A9 51%,#CCE7A9 100%);
	background:  -webkit-linear-gradient(to bottom, #ABCB7F 0%,#ABCB7F 50%,#CCE7A9 51%,#CCE7A9 100%);
	background:  -o-linear-gradient(to bottom, #ABCB7F 0%,#ABCB7F 50%,#CCE7A9 51%,#CCE7A9 100%);
	background:  -ms-linear-gradient(to bottom, #ABCB7F 0%,#ABCB7F 50%,#CCE7A9 51%,#CCE7A9 100%);
	background:  linear-gradient(to bottom, #ABCB7F 0%,#ABCB7F 50%,#CCE7A9 51%,#CCE7A9 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' #ABCB7F', endColorstr='#CCE7A9', GradientType=1 );
}
.swallowtail-blue {
	background-color: #74A8C3;
	background:  -moz-linear-gradient(to bottom, #74A8C3 0%,#74A8C3 50%,#B9D4EA 51%,#B9D4EA 100%);
	background:  -webkit-linear-gradient(to bottom, #74A8C3 0%,#74A8C3 50%,#B9D4EA 51%,#B9D4EA 100%);
	background:  -o-linear-gradient(to bottom, #74A8C3 0%,#74A8C3 50%,#B9D4EA 51%,#B9D4EA 100%);
	background:  -ms-linear-gradient(to bottom, #74A8C3 0%,#74A8C3 50%,#B9D4EA 51%,#B9D4EA 100%);
	background:  linear-gradient(to bottom, #74A8C3 0%,#74A8C3 50%,#B9D4EA 51%,#B9D4EA 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' #74A8C3', endColorstr='#B9D4EA', GradientType=1 );
}

#header {
	background-image: url("/assets/img/newborns/wavegrid_@2X.png");
}

#header div {
    margin-top: 6rem;
    margin-bottom: 10rem;
}

#intro {
	background-image: url("/assets/img/newborns/cloth_alike_@2X.png");
}
#obConsults {
    background-image: url("/assets/img/newborns/satinweave_@2X.png");
    padding-top: 6rem;
    padding-bottom: 6rem;
}

#hospitals {
    background-image: url("/assets/img/newborns/satinweave_@2X.png");
    padding-top: 8rem;
    padding-bottom: 10rem;
}
#newbornServices {
	background-image: url("/assets/img/newborns/cloth_alike_@2X.png");
	padding-top: 8rem;
    padding-bottom: 10rem;
}
#firstVisit {
	background-image: url("/assets/img/newborns/wavegrid.png");
	padding-top: 4rem;
    padding-bottom: 6rem;
}
#vaccines {
	background-image: url("/assets/img/newborns/satinweave_@2X.png");
	padding-top: 8rem;
    padding-bottom: 10rem;
}
#resources{
	background-image: url("/assets/img/newborns/wavegrid.png");
	padding-top: 6rem;
    padding-bottom: 10rem;
}

.d-ib {
	display: inline-block;
	vertical-align: middle;
}

//.box.entered {

//}

.box.entered-active {
    width: 75%;
}

.expecting-h1 {
	font-family: 'Abril Fatface', cursive !important;
	color: #55595c !important;
	font-size: 55px;
}

.expecting-h2 {
	font-size: 25px;
}

.expecting-p {
	font-size: 20px;
}

.uparrowdiv {
	position: relative;
}

.uparrowdiv:after {
	content: '';
	display: block;
	position: absolute;
	top: -60px;
	left: 40px;
	width: 0;
	height: 0;
	border-color: transparent transparent white transparent;
	border-style: solid;
	border-width: 30px;
}

.v-a_t {
	vertical-align: top;
}

.swallowTailSpan-green {
	background-color: #5DA47D;
}

.swallowTailSpan-pink {
	background-color: #F4CDE5;
}

.swallowTailSpan-lightorange {
	background-color: #EDCF92;
}

.swallowTailSpan-orange {
	background-color: #DDA952;
}

.swallowTailSpan-teal {
	background-color: #BBE7E5;
}

.swallowTailSpan-blue {
	background-color: #74A8CE;
}

.swallowTailSpan {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 35px;
}

.swallowTailSpan:after {
	content: '';
	display: block;
	position: absolute;
	top: 15px;
	width: 0;
	height: 0;
	border-color: transparent transparent white transparent;
	border-style: solid;
	border-width: 10px;
}

.ob-consult-call-box {
    border: 4px dashed #505050;
    padding: .7rem;
}

.ob-consult-call-box-a {
    color: #515151;
    font-size: 20px;
    text-decoration: none;
}

.hospitals-locations-box {
    border: 4px dashed #505050;
}

.first-visit-ul li {
	position: relative;
}

.first-visit-ul li svg {
	position: absolute;
	top: -3px;
	left: -25px;
}

.resources-lists li {
	margin-bottom: 1rem;
}

.resources-lists li span {
	margin-right: .5rem;
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}


@media (min-width: 1170px) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
