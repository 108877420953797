#patient-portal-hero {
    height: 693px;
    background-color: #88d4ea;
	background-image: url($site-url+'/assets/img/patient-portal/Starburst-01.png');
	background-position: center top;
    background-repeat: no-repeat;
}

#patient-portal-hero p {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 50%;
    width: 170px;
    height: 170px;
    padding-top: 25px;
    -webkit-box-shadow: 0px 0px 15px -1px rgba(255,255,255,1);
	-moz-box-shadow: 0px 0px 15px -1px rgba(255,255,255,1);
	box-shadow: 0px 0px 15px -1px rgba(255,255,255,1);
	font-size: 18px;
	color: #777;
	line-height: 2.2;
}