
.triangle-center {
    position: relative;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid white;
    margin: 0 auto;
}

#NextgenPortal {
    padding-top: 4rem;
    padding-bottom: 5rem;
    font-size: 1.2rem;
}

#NextgenPortal h1 {
    font-size: 3rem;
}

#mission {
    padding-top: 4rem;
    padding-bottom: 5rem;
    font-size: 1.2rem;
}

#mission h1 {
    font-size: 3rem;
}

#locations {
    padding-top: 4rem;
    padding-bottom: 5rem;
}

#locations h1 {
    color: white;
    letter-spacing: 10px;
}

.locations-img {
    border: solid 6px white;
}

.explore-card {
	background-size: cover;
	background-position: center;
}

.explore-card > div {
    height: 300px;
}

.explore-card-text {
	font-size: 32px;
	color: white !important;
    letter-spacing: 5px;
    
}

.explore-card-text:hover {
    text-decoration: underline;
    text-decoration-color: white;
}
